/* frontend/src/components/TopMenu.css */
header {
  color: transparent !important;
}
.ant-menu.ant-menu-horizontal {
  background: transparent !important; /* Overstyr baggrundsfarven */
  display: flex;
  justify-content: center; /* Centrerer menu-elementerne horisontalt */
  border-bottom: none; /* Fjerner eventuel bundlinje */
}

.ant-menu.ant-menu-horizontal .ant-menu-item a {
  color: rgb(78, 75, 77) !important;
  font-size: 20px; /* Øger fontstørrelsen */
  text-align: center; /* Centrerer teksten inden for hvert menu-element */
  padding: 16px 24px; /* Øger padding for større klikområde */
}

/* Menu styling - hover og valgte elementer */
.ant-menu.ant-menu-horizontal .ant-menu-item a:hover {
  color: #f34747 !important; /* Primær farve ved hover */
}

.ant-menu.ant-menu-horizontal .ant-menu-item-selected a {
  color: #ff1818 !important; /* Primær farve for aktivt link */
}

/* Overstyr stil for valgte menupunkter for at ændre understrengen */
.ant-menu-horizontal > .ant-menu-item-selected::after, 
.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-width: 2px;
  border-bottom-color: #fd0404 !important; /* Farve for understrengen på valgte elementer */
}

/* Layout header styling */
.ant-layout-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3; /* Sikrer at headeren ligger over overlayet */
  background: transparent !important; /* Transparent baggrund */
}
/* Understregning af menupunkter ved hover og ved valg */
.ant-menu-horizontal > .ant-menu-item:hover::after, 
.ant-menu-horizontal > .ant-menu-submenu:hover::after {
  border-bottom-width: 2px;
  border-bottom-color: #fd0404 !important; /* Farve for understrengen ved hover */
  content: ''; /* Sikrer, at ::after vises */
  display: block; /* Vis understrengen */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}