/* frontend/src/components/AlbumList.css */
.album-list-container {
  /*position: relative; /* Nødvendig for overlay */
  width: 100%;
  height: 100vh; /* Fuldt skærm */
  overflow: hidden; /* Fjern scrollbars */
}
.fullscreen-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Sørg for, at billedet dækker hele skærmen uden at forvrænge det */
  z-index: 1; /* Sætter billedet bag overlayet */
}

.overlay {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centrerer overlayet */
  text-align: center;
  color: white; /* Kontrastfarve mod billedet */
  z-index: 2; /* Over billedet */
  padding: 0 20px; /* Lidt padding for bedre læsbarhed */
  background: rgba(0, 0, 0, 0.4); /* Valgfrit: Delvis gennemsigtig baggrund for bedre kontrast */
  border-radius: 10px; /* Valgfrit: Runde hjørner */
}

.album-list-title {
  color: white;
  font-size: 36px;
}

.album-list-date {
  color: white;
  font-size: 24px;
}
