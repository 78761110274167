/* frontend/src/components/AlbumPage.css */
header {
  color: transparent !important;
}
.album-page-container {
    padding: 20px;
    text-align: center;
  }
  
  .album-page-container h2 {
    color:  transparent; /*#001529; /* Ant Design's default Header farve */
    margin-bottom: 20px;
  }
  
  .album-page-container video {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
  }
  .download-button {
    display: inline-block;
    margin-top: 8px;
    padding: 6px 12px;
    background-color: #1890ff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    text-align: center;
  }
  
  .download-button:hover {
    background-color: #40a9ff;
  }
  .download-button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #9d8787;
    cursor: pointer;
    display: inline-block;
    margin-top: 8px;
    padding: 6px 12px;
    padding: 8px 8px;
    text-align: center;
    text-decoration: none;
    transition: background-color .3s;
    transition: all .3s;
  }
  
  .download-button:hover {
    background-color: #40a9ff; /* Mørkere blå ved hover */
  }
  
  .download-button .anticon {
    font-size: 16px; /* Juster ikonstørrelse efter behov */
    margin-right: 8px; /* Tilføj margin mellem ikon og tekst (hvis du tilføjer tekst igen) */
  }
  /* frontend/src/components/AlbumPage.css */

.album-page-container {
  padding: 20px;
}

.media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-button {
  display: inline-block;
  margin-top: 8px;
  padding: 6px 12px;
  background-color: transparent;
  color: rgb(53, 50, 50);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-align: center;
}

.download-button:hover {
  background-color: #40a9ff;
}
